
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { useRouter } from "vue-router";

  import mAxiosApi from "@/api";

  export default defineComponent({
      name: "Overview",
      props: {
        mCode: String,
      },
      components: {Datatable},
      setup(props) {

      const store = useStore();

      const router = useRouter();

      const state = reactive({
          networks : [] as any,
          options: [] as any,
          valueOrga : 0,
          loaderEnabled: true,
          myList: [] as any,
          initialMyList: [] as any,
          loadingDatatable: 0,
          drawer : false,
          currentNetwork: "",
          form : {} as any,
          loaderEnabledTest : false,
          typeEddEdit : "",
          nameOrga : "",
          ssOrga : [] as any,
          loadingDatatableSSOrga : 0,
          BackupssOrga : [] as any,
          reloadSSOrgaSelect : 0,
      });

      const tableHeader = ref([
        { name: "Réseau", key: "name", sortable: false},
        { name: "Type", key: "productTypes", sortable: false,},
        { name: "Sous Organisation", key: "sousOrga", sortable: false,},
        { name: "", key: "users", sortable: false,},
      ]);

      const tableHeaderSousOrga = ref([
        { name: "Sous Organisation", key: "ssm_sous_orga", sortable: false,},
        { name: "Actions", key: "action", sortable: false,},
      ]);

      const ssOrga = (id) => {
        const retOrga = state.ssOrga.filter(el => el.ssm_seq == id)[0].ssm_sous_orga;
        return retOrga;
      };

      onMounted(async () => {

        setCurrentPageBreadcrumbs("Paramétrage des sous organisations Meraki", []);

        const mCode = props.mCode && props.mCode.length > 0 ? props.mCode : (router.currentRoute.value.params.code as string);

        let myOrga = await mAxiosApi.prototype.getAxios("/testAPIMerakiUser");

        state.nameOrga = myOrga.results.filter(el => el.id == mCode)[0].name;

        let zNetworks = await getAxios("/getOrgaNetworks/" + mCode);
        state.myList = zNetworks.results;
        state.initialMyList = state.myList;

        state.ssOrga = zNetworks.ssOrga;

        state.loaderEnabled = false;

      });

      const changeSousOrga = async (idNetwork, idSousOrga) => {
        state.myList.filter(el => el.id == idNetwork)[0].sousOrga = idSousOrga;
        state.initialMyList.filter(el => el.id == idNetwork)[0].sousOrga = idSousOrga;

        const mCode = props.mCode && props.mCode.length > 0 ? props.mCode : (router.currentRoute.value.params.code as string);

        const formSousOrga = {
          lns_id_network : idNetwork,
          lns_knum_sous_orga : idSousOrga,
          lns_id_orga : mCode,
        }
        await mAxiosApi.post("/changeSousOrgaNetworks", formSousOrga);
        
      }
      
      const gestSousOrga = () => {
        state.drawer = true;
        state.BackupssOrga = JSON.stringify(state.ssOrga);

      }

      const cancelGestSSOrga = () => {
        state.drawer = false;
        state.ssOrga = JSON.parse(state.BackupssOrga);
      }

      const addSousOrga = () => {
        let minSeq1 = Math.min(...state.ssOrga.map(item => Number(item.ssm_seq)));
        if (minSeq1 > 0) {
          minSeq1 = -1;
        } else {
          minSeq1 -= 1;
        }
        state.ssOrga.push( { ssm_seq: minSeq1, ssm_knum_societe: 0, ssm_sous_orga: "" })
      }

      const delSousOrga = (id) => {
        const index = state.ssOrga.findIndex(el => el.ssm_seq == id);
        if (index !== -1) {
            if (Number(state.ssOrga[index].ssm_seq) < 0) {
                state.ssOrga.splice(index, 1);
            } else {
                state.ssOrga[index].toDel = true;
            }
        }
      }

      const notDelSousOrga = (id) => {
        const index = state.ssOrga.findIndex(el => el.ssm_seq == id);
        state.ssOrga[index].toDel = false;
      }

      const saveGestSSOrga = async () => {

        const mArrayDel = state.ssOrga.filter(el => el.toDel == true);
        mArrayDel.forEach(el => {
          const mArrayDelSS = state.myList.filter(el2 => el2.sousOrga == el.ssm_seq);
          mArrayDelSS.forEach(el3 => {
            el3.sousOrga = "";
          });
        });

        const results = await mAxiosApi.post("/addEditSousOrgaNetworks", state.ssOrga);
        state.ssOrga = results.data.results;
        state.reloadSSOrgaSelect += 1;
        state.drawer = false;
      }

      return {
        state,
        store,
        tableHeader,
        ssOrga,
        changeSousOrga,
        tableHeaderSousOrga,
        gestSousOrga,
        cancelGestSSOrga,
        addSousOrga,
        saveGestSSOrga,
        delSousOrga,
        notDelSousOrga
        
      };

    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  