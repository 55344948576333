<template>
    <transition name="fade">
      <div class="card" style="width: 100%;" v-if="!state.loaderEnabled">
        <div class="card-header border-0 pt-6" style="display: flex; justify-content: space-between; align-items: stretch; flex-wrap: nowrap; min-height: 70px; padding: 0 2.25rem; background-color: transparent; flex-direction: row;">
          <el-divider content-position="left"><h2>Réseaux Meraki de l'organisation {{ state.nameOrga }}</h2></el-divider>
          <button class="btn btn-primary mx-2" style="width: 320px;" @click="gestSousOrga()">Gérer les sous organisations</button>        
        </div>
        <div class="card-body pt-0">
            
          

          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            empty-table-text="Aucune ligne à afficher."
            :key="state.loadingDatatable"
            class="mt-4 table-small-td cursor-pointer"
          >

            <template v-slot:cell-name="{ row: data }">
              <div  class="fs-5 text-gray-700">{{ data.name }}</div>
            </template>
            
            <template v-slot:cell-productTypes="{ row: data }">
                <el-tag v-for="(type, index) in data.productTypes" :key="index"  size="large" class="mx-1" effect="plain" >{{ type }}</el-tag>
            </template>

            <template v-slot:cell-sousOrga="{ row: data }">
              <el-select
                v-model="data.sousOrga"
                placeholder="Aucune Sous Organisation"
                class="mt-4 w-350px"
                @change="changeSousOrga(data.id, data.sousOrga)"
                :key="state.reloadSSOrgaSelect"
              >
                <el-option v-for="item, index in state.ssOrga" :key="index" :label="item.ssm_sous_orga" :value="item.ssm_seq" />
              </el-select>
              <button v-if="data.sousOrga" @click="changeSousOrga(data.id, '')" class="btn btn-white btn-sm me-2 " title="Supprimer la sous organisation" style="width: 50px;">❌</button>

            </template>

            <template v-slot:cell-users>
              
            </template>

          </Datatable>


        </div>
      </div>
    </transition>

    <el-dialog :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" v-model="state.drawer" title="Gestion des sous organisations" width="1000">

      <div>
        <button class="btn btn-sm btn-secondary mx-2" style="width: 320px; " @click="addSousOrga()">➕ Ajouter une sous organisation</button>      
      </div>
        
      <div style="max-height: 400px;  overflow-y: auto;">
        <Datatable
              v-if="state.drawer && state.ssOrga && state.ssOrga.length > 0"
              :table-data="state.ssOrga"
              :table-header="tableHeaderSousOrga"
              :enable-items-per-page-dropdown="false"
              :rows-per-page="100"
              empty-table-text="Aucune ligne à afficher."
              :key="state.loadingDatatableSSOrga"
              class="table-small-td"
             
            >
            <!--  @click-function="selectLineObjGroup($event)" -->
              <template v-slot:cell-ssm_sous_orga="{ row: data }">
                <div class="mx-2 text-gray-900">
                  <el-input v-if="!data.toDel" type="text" class="mb-3 mb-lg-0" placeholder="Nom de la sous organisation" v-model="state.ssOrga.filter(el => el.ssm_seq == data.ssm_seq)[0].ssm_sous_orga" />
                   <span v-if="data.toDel" class="mx-2" style="text-decoration:line-through; color: red">{{ state.ssOrga.filter(el => el.ssm_seq == data.ssm_seq)[0].ssm_sous_orga }}</span>
                  <!-- @change="selectLineObjGroup(data)" -->
                </div>              
              </template>
            
              <template v-slot:cell-action="{ row: data }">
                <button v-if="!data.toDel" @click="delSousOrga(data.ssm_seq)" class="btn btn-white btn-sm me-2 " title="Supprimer la sous organisation" style="width: 50px;">❌</button>
                <button v-if="data.toDel" @click="notDelSousOrga(data.ssm_seq)" class="btn btn-white btn-sm me-2 " title="Ne pas supprimer" style="width: 50px;">✔️</button>            
              </template>
              
            </Datatable>    
          </div> 
          <template #footer>
            <span class="dialog-footer">
              <el-button class="btn btn-secondary" @click="cancelGestSSOrga()">Annuler</el-button>
              <el-button class="btn btn-primary" @click="saveGestSSOrga()">Enregistrer</el-button>
            </span>
          </template>
          
    </el-dialog>

  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { useRouter } from "vue-router";

  import mAxiosApi from "@/api";

  export default defineComponent({
      name: "Overview",
      props: {
        mCode: String,
      },
      components: {Datatable},
      setup(props) {

      const store = useStore();

      const router = useRouter();

      const state = reactive({
          networks : [] as any,
          options: [] as any,
          valueOrga : 0,
          loaderEnabled: true,
          myList: [] as any,
          initialMyList: [] as any,
          loadingDatatable: 0,
          drawer : false,
          currentNetwork: "",
          form : {} as any,
          loaderEnabledTest : false,
          typeEddEdit : "",
          nameOrga : "",
          ssOrga : [] as any,
          loadingDatatableSSOrga : 0,
          BackupssOrga : [] as any,
          reloadSSOrgaSelect : 0,
      });

      const tableHeader = ref([
        { name: "Réseau", key: "name", sortable: false},
        { name: "Type", key: "productTypes", sortable: false,},
        { name: "Sous Organisation", key: "sousOrga", sortable: false,},
        { name: "", key: "users", sortable: false,},
      ]);

      const tableHeaderSousOrga = ref([
        { name: "Sous Organisation", key: "ssm_sous_orga", sortable: false,},
        { name: "Actions", key: "action", sortable: false,},
      ]);

      const ssOrga = (id) => {
        const retOrga = state.ssOrga.filter(el => el.ssm_seq == id)[0].ssm_sous_orga;
        return retOrga;
      };

      onMounted(async () => {

        setCurrentPageBreadcrumbs("Paramétrage des sous organisations Meraki", []);

        const mCode = props.mCode && props.mCode.length > 0 ? props.mCode : (router.currentRoute.value.params.code as string);

        let myOrga = await mAxiosApi.prototype.getAxios("/testAPIMerakiUser");

        state.nameOrga = myOrga.results.filter(el => el.id == mCode)[0].name;

        let zNetworks = await getAxios("/getOrgaNetworks/" + mCode);
        state.myList = zNetworks.results;
        state.initialMyList = state.myList;

        state.ssOrga = zNetworks.ssOrga;

        state.loaderEnabled = false;

      });

      const changeSousOrga = async (idNetwork, idSousOrga) => {
        state.myList.filter(el => el.id == idNetwork)[0].sousOrga = idSousOrga;
        state.initialMyList.filter(el => el.id == idNetwork)[0].sousOrga = idSousOrga;

        const mCode = props.mCode && props.mCode.length > 0 ? props.mCode : (router.currentRoute.value.params.code as string);

        const formSousOrga = {
          lns_id_network : idNetwork,
          lns_knum_sous_orga : idSousOrga,
          lns_id_orga : mCode,
        }
        await mAxiosApi.post("/changeSousOrgaNetworks", formSousOrga);
        
      }
      
      const gestSousOrga = () => {
        state.drawer = true;
        state.BackupssOrga = JSON.stringify(state.ssOrga);

      }

      const cancelGestSSOrga = () => {
        state.drawer = false;
        state.ssOrga = JSON.parse(state.BackupssOrga);
      }

      const addSousOrga = () => {
        let minSeq1 = Math.min(...state.ssOrga.map(item => Number(item.ssm_seq)));
        if (minSeq1 > 0) {
          minSeq1 = -1;
        } else {
          minSeq1 -= 1;
        }
        state.ssOrga.push( { ssm_seq: minSeq1, ssm_knum_societe: 0, ssm_sous_orga: "" })
      }

      const delSousOrga = (id) => {
        const index = state.ssOrga.findIndex(el => el.ssm_seq == id);
        if (index !== -1) {
            if (Number(state.ssOrga[index].ssm_seq) < 0) {
                state.ssOrga.splice(index, 1);
            } else {
                state.ssOrga[index].toDel = true;
            }
        }
      }

      const notDelSousOrga = (id) => {
        const index = state.ssOrga.findIndex(el => el.ssm_seq == id);
        state.ssOrga[index].toDel = false;
      }

      const saveGestSSOrga = async () => {

        const mArrayDel = state.ssOrga.filter(el => el.toDel == true);
        mArrayDel.forEach(el => {
          const mArrayDelSS = state.myList.filter(el2 => el2.sousOrga == el.ssm_seq);
          mArrayDelSS.forEach(el3 => {
            el3.sousOrga = "";
          });
        });

        const results = await mAxiosApi.post("/addEditSousOrgaNetworks", state.ssOrga);
        state.ssOrga = results.data.results;
        state.reloadSSOrgaSelect += 1;
        state.drawer = false;
      }

      return {
        state,
        store,
        tableHeader,
        ssOrga,
        changeSousOrga,
        tableHeaderSousOrga,
        gestSousOrga,
        cancelGestSSOrga,
        addSousOrga,
        saveGestSSOrga,
        delSousOrga,
        notDelSousOrga
        
      };

    },
  });
  
  export const getAxios = async (rr) => { const re = await mAxiosApi.get(rr); return re.data; };

  </script>